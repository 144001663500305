import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const BlockHero = ({ data }) => {
  //console.log("Block Hero: ", data)

  const {
    image,
    logo,
    //title,
    callToAction,
    Buttons,
  } = data

  return (
    <section id="home">
      <div className="uk-cover-container uk- uk-margin-remove-bottom">
        <GatsbyImage
          image={getImage(image?.localFile)}
          alt="Adu dev hero image"
          style={{ height: "650px" }}
        />

        <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
          <div className="uk-flex uk-flex-center">
            <div className="uk-width-3-3 uk-margin-top">
              <div className="uk-heading-medium uk-text-center uk-light uk-animation-fade uk-animation-slide-top-medium uk-margin-medium-top">
                <StaticImage
                  src="../images/stsp-banner-03.png"
                  alt="save the street pooches banner orange to grey graident"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  title="save the street pooches banner orange to grey graident"
                  style={{ maxWidth: "280px" }}
                />
              </div>

              {logo ? (
                <>
                  <div className=" uk-text-center uk-animation-fade uk-animation-slide-right-medium uk-margin-medium-bottom uk-visible@m">
                    <GatsbyImage
                      image={getImage(logo?.localFile)}
                      alt="save the street pooches logo"
                    />
                  </div>
                  <div className=" uk-text-center uk-animation-fade uk-animation-slide-right-medium uk-margin-medium-bottom uk-hidden@m">
                    <GatsbyImage
                      image={getImage(logo?.localFile)}
                      alt="save the street pooches logo"
                    />
                  </div>
                </>
              ) : null}

              <div>
                <h3
                  className="uk-animation-fade uk-animation-slide-left-medium uk-visible@s"
                  style={{ maxWidth: "800px" }}
                >
                  {callToAction}
                </h3>
              </div>

              <div className="heroText">
                <h4 className="uk-animation-fade uk-animation-slide-left-medium uk-hidden@s">
                  {callToAction}
                </h4>
              </div>

              <div className="uk-flex uk-flex-center uk-flex-middle uk-margin">
                <div className="uk-margin-bottom">
                  {Buttons?.map(button => {
                    //console.log("Button:", button)
                    return (
                      <Link
                        key={button.strapi_id}
                        to={button.link}
                        title={button.name}
                        alt={button.name}
                        toggle="button"
                        className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                        style={{
                          borderRadius: "8px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <span>{button.name}</span>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockHero
